import '/assets/styles/pages/checkout-payment.scss';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CreditCards from 'creditcards'

// Functions
import getCardTypeImage from '/functions/get-card-type-image'
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';

// Partials
import { Form, Input, FormFeedback } from '/views/partials/forms';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Loader from '/views/partials/loader';
import Btn from '/views/partials/btn';
import Pricebox from '/views/partials/pricebox';
import Placeholder from '/views/partials/placeholder';
import PromoCodeForm from '/views/partials/promo-code-form';

// Context
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useWallet } from "/utilities/wallet";

// Services
import walletServices from '/services/wallet';
import bookingServices from '/services/booking';

// Static Assets
import image_creditcard_chip from '/assets/images/payment/creditcard-chip.svg';

const getInitialStatusMessage = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const errorMessage = urlParams.get('error');
	const walletSuccess = urlParams.get('wallet-success') === '1';
	
	if(walletSuccess) {
		return {
			walletSuccess: true,
			feedback: false,
		}
	}
	else if(errorMessage !== null) {
		return {
			walletSuccess: false,
			feedback: {
				error: [`Ödeme alınamadı. Hata: "${errorMessage}"`],
			}
		}
	}
	else {
		return {
			walletSuccess: false,
			feedback: false,
		};
	}
};

const parseHead = (event) => {
	if(event?.calendar?.full_date) {
		const eventDate = formatDate(event.calendar.full_date, 'd MMMM');
		return {
			title: `Ödeme - ${event.title} / ${eventDate} ${event.calendar.event_location}`,
			description: `${eventDate} tarihli ${event.calendar.event_location} ${event.title} etkinliği bileti için ödeme yapın.`,
			image: event.image?.asset_paths?.original,
		}
	}
	
	return {
		title: 'Ödeme',
	}
}

const Checkout = () => {
	const { setHead } = useHead();
	const { desktop } = useBreakpoints();
	const { redirect } = useNavigator();
	const { openModal } = useModals();
	const { data: walletData, updateAll: getWalletData } = useWallet();

	const [sale, setSale] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [feedbackData, setFeedbackData] = useState(null);
	const [payWithWallet, setPayWithWallet] = useState(false);

	const [cardName, setCardName] = useState('');
	const [cardNumber, setCardNumber] = useState('');
	const [expiryNumber, setExpiryNumber] = useState('');

	const { binNumber, displayCardNumber } = useMemo(() => ({
		binNumber: (cardNumber && cardNumber.length >= 6 ? cardNumber.replace(/ /g, '').substring(0, 6) : false),
		displayCardNumber: cardNumber + '---- ---- ---- ----'.substr(cardNumber.length, 19),
	}), [cardNumber])

	const displayExpiryNumber = useMemo(() => {
		return expiryNumber + '--/--'.substr(expiryNumber.length, 5);
	}, [expiryNumber])

	const { fullyPayable, walletPaymentCardTotal } = useMemo(() => {
		if(walletData?.balance && sale && payWithWallet) {
			const walletPaymentCardTotal = sale.total_price - walletData.balance;
	
			return {
				fullyPayable: walletPaymentCardTotal <= 0,
				walletPaymentCardTotal: walletPaymentCardTotal < 0 ? 0 : walletPaymentCardTotal,
			};
		}
		else {
			return {
				fullyPayable: false,
				walletPaymentCardTotal: 0,
			}
		}

	}, [payWithWallet, sale, walletData])

	const cardType = useMemo(() => {
		return binNumber ? CreditCards.card.type(binNumber.replace(/}D/g,''), true) : false;
	}, [binNumber])

	const cardTypeImage = useMemo(() => {
		return getCardTypeImage(cardType);
	}, [cardType])

	const getBooking = useCallback((bookingId, walletSuccess) => {
		if(bookingId) {
			bookingServices.getBooking(bookingId).then((payload) => {
				if(payload.status === 1) {
					redirect('checkoutSummary', { id: payload.id });
				}
				else {
					const saleData = {
						...payload,
						event: {
							...payload.event,
							dateTimeString: formatDate(payload.event.calendar.full_date, 'dd.MM.y - HH:mm')
						}
					};
	
					if(walletSuccess) {
						completePaymentByWallet(payload.id).then(() => {
						}).catch(() => {
							setSale(saleData);
						})
					}
					else {
						setSale(saleData)
					}
				}
			}).catch((feedback) => {
				openModal('message', { feedback });
				redirect('checkout');
			})
		}
	}, [])

	useEffect(() => {
		const bookingId = window.localStorage.getItem(process.config.checkout.salePersistenceKey);

		if(bookingId) {
			getWalletData();
			const { feedback: initialFeedback, walletSuccess } = getInitialStatusMessage();
			setFeedbackData(initialFeedback);

			// /bilet-satin-al/odeme?wallet-success=1
			redirect('payment', {}, { replace: true, force: true });
			
			getBooking(bookingId, walletSuccess);
		}
		else {
			openModal('message', { message: 'Sipariş bulunamadı.' });
			redirect('checkout');
		}
	}, [])

	useEffect(() => {
		if(sale?.event) {
			setHead(parseHead(sale.event));
		}
	}, [sale])

	const paymentByWallet = (formFields) => {
		setSubmitting(true);

		const [expiry_month, expiry_year] = formFields.expiry.split('/');

		walletServices.preparePayment(walletPaymentCardTotal, true).then((sale) => {
			walletServices.makePayment(sale.id, {
				holder_name: formFields.holder_name,
				card_number: formFields.card_number.replace(/\s+/g, ''),
				expire_year: expiry_year,
				expire_month: expiry_month,
				cvv: formFields.cvv,
			}).then(() => {
				redirect('checkoutSummary', { id: sale.id });
			}).catch((feedback) => {
				setFeedbackData(feedback);
				setSubmitting(false);
			})
		}).catch((feedback) => {
			setFeedbackData(feedback);
			setSubmitting(false);
		});
	}

	const paymentByCard = (formFields) => {
		setSubmitting(true);

		const [expiry_month, expiry_year] = formFields.expiry.split('/');

		bookingServices.makePayment(sale.id, {
			holder_name: formFields.holder_name,
			card_number: formFields.card_number.replace(/\s+/g, ''),
			expire_year: expiry_year,
			expire_month: expiry_month,
			cvv: formFields.cvv,
		}).then(() => {
			redirect('checkoutSummary', { id: sale.id });
		}).catch((feedback) => {
			setFeedbackData(feedback);
			setSubmitting(false);
		});
	}

	const completePaymentByWallet = (saleId) => {
		return new Promise((resolve, reject) => {
			setSubmitting(true);
			bookingServices.makePaymentWithWallet(saleId).then((saleId) => {
				redirect('checkoutSummary', { id: saleId });
				resolve(saleId);
			}).catch((feedback) => {
				setFeedbackData(feedback);
				setSubmitting(false);
				reject(false);
			})
		})
	}

	const submit = (formFields) => {
		if(payWithWallet) {
			if(walletPaymentCardTotal) {
				paymentByWallet(formFields);
			}
			else {
				completePaymentByWallet(sale.id);
			}
		}
		else {
			paymentByCard(formFields)
		}
		
	}

	return (
		<Form
			onSubmit={submit}
			className="layout-checkout-content wrapper loader-container">
			<Loader dark loading={!sale} />
			{!!sale &&
				<>
					<div className="checkout-maincontent">
						<main className="checkout-section">
							<div className="checkout-section-header">
								<h1 className="header-title">Ödeme</h1>
							</div>

							<FormFeedback className="payment-feedback" data={feedbackData} />

							{!!walletData?.balance &&
								<div className="payment-walletinfo">
									<Icon
										className="walletinfo-icon"
										name="wallet-add" />

									<div className="walletinfo-content">
										<strong className="walletinfo-title">IF Cüzdan</strong>

										<div className="walletinfo-balance">
											Cüzdan Bakiyeniz:{` `}
											{walletData ?
												<Pricebox
													className="balance-amount"
													price={walletData.balance} />
												:
												<Placeholder className="balance-amount" />
											}
										</div>

										{!!walletData?.balance &&
											<Input
												className="walletinfo-useinput"
												name="use-wallet-balance"
												uncheckedValue="0"
												defaultValue="1"
												checked={payWithWallet}
												onChange={(val) => {
													setPayWithWallet(val === '1');
												}}
												type="checkbox">
												Bakiyemi kullanmak istiyorum.
											</Input>
										}
									</div>

									<div className="walletinfo-description">
										Cüzdanınızdaki bakiyeniz ile bilet satın alabilir veya mevcut bakiyenizi bilet ücretinden düşerek kalan tutarı kartınız ile ödeyebilirsiniz.
									</div>
								</div>
							}

							<strong className="payment-instructions">
								{fullyPayable ?
									'Ödemenizin tamamı cüzdanınızdan gerçekleştirilecek'
									:
									<>
										{payWithWallet ?
											'Kalan tutarın çekilmesi için kart bilgilerinizi aşağıdaki alana giriniz'
											:
											'Kart bilgilerinizi aşağıdaki alana giriniz'
										}
									</>
								}
							</strong>

							{!fullyPayable &&
								<div className="payment-cardinfo">
									<div className="cardinfo-fields">
										<Input
											className="fields-name"
											name="holder_name"
											disabled={submitting}
											type="text"
											value={cardName}
											onChange={setCardName}
											autoComplete="cc-name"
											validation={{
												required: 'Kart üzerindeki isim alanını girmelisiniz.',
												fullName: 'Geçerli bir isim girmelisiniz.',
											}}
											label="Kartın Üzerindeki İsim" />
										<Input
											className="fields-cardnumber"
											name="card_number"
											disabled={submitting}
											id="input_checkout_card_number"
											type="text"
											value={cardNumber}
											mask="creditcard"
											onChange={setCardNumber}
											autoComplete="cc-number"
											validation={{
												required: 'Kart numaranızı girmelisiniz.',
												cardNumber: true,
											}}
											label="Kart Numarası" />
										<Input
											className="fields-expiry"
											name="expiry"
											disabled={submitting}
											type="text"
											value={expiryNumber}
											onChange={setExpiryNumber}
											mask="expiry"
											autoComplete="cc-exp"
											validation={{
												required: 'SKT seçmelisiniz.',
											}}
											label="SKT" />
										<Input
											className="fields-cvv"
											name="cvv"
											disabled={submitting}
											type="number"
											mask="numeric"
											maxLength={3}
											autoComplete="cc-csc"
											validation={{
												required: 'CVV girmelisiniz.',
												cardCvv: [true, cardType],
											}}
											label="CVC" />
									</div>

									{desktop &&
										<div className="cardinfo-preview">
											<Img
												className="preview-type"
												src={cardTypeImage} />
											<Img
												className="preview-chip"
												src={image_creditcard_chip} />
											<div className="preview-cardnumber">{displayCardNumber}</div>
											<div className="preview-cardname">{cardName}</div>
											<div className="preview-expiry">{displayExpiryNumber}</div>
										</div>
									}
								</div>
							}
						</main>
					</div>
									
					<aside className="checkout-aside">
						<div className="aside-info">
							<div className="info-event">
								<Img
									className="event-image"
									src={sale.event.image?.asset_paths?.thumbnail} />

								<div className="event-text">
									<strong className="event-title">{sale.event.title}</strong>

									<div className="event-field">
										<Icon name="map-pin" /> {sale.event.calendar.event_location}
									</div>

									<div className="event-field">
										<Icon name="map-pin" /> {sale.event.dateTimeString}
									</div>
								</div>
							</div>

							<PromoCodeForm sale={sale} onSuccess={() => {
								getBooking(sale?.id);
							}} />

							{sale.products?.length > 0 &&
								<>
									<div className="info-tickets">
										<strong className="tickets-title">Biletleriniz:</strong>
										<ul className="tickets-list">
											{sale.products.map((product, nth) => (
												<li className="tickets-ticket" key={nth}>
													<span className="ticket-title">
														{product.option && <>{product.option.title} - </>}{product.option_slot?.title}
													</span>
													{product.option?.price &&
														<Pricebox
															className="ticket-price"
															price={product.option.price} />
													}
												</li>
											))}
										</ul>
									</div>

									<ul className="info-prices">
										<li className="prices-item">
											<span>Bilet Fiyatı</span>
											<Pricebox className="item-price" price={sale.product_total_price} />
										</li>
										{sale.extras.map((extra) => (
											<li className="prices-item" key={extra.id}>
												<span>{extra.title}</span>
												<Pricebox className="item-price" price={extra.price} />
											</li>
										))}
										<li className="prices-item total">
											<span>Toplam</span>
											<Pricebox className="item-price" price={sale.total_price} />
										</li>

										{!!(!fullyPayable && walletPaymentCardTotal) &&
											<li className="prices-item highlight">
												<span>Karttan Çekilecek Tutar</span>
												<Pricebox className="item-price" price={walletPaymentCardTotal} />
											</li>
										}
									</ul>
								</>
							}
						</div>

						<div className="aside-controls">
							<Input
								className="controls-agreement"
								name="agreement"
								validation={{
									required: 'Devam etmek için mesafeli satış sözleşmesini kabul etmelisiniz.'
								}}
								type="checkbox">
								<button type="button" className="hyperlink" onClick={() => { openModal('agreement', { agreement: 'SALE_TERM' }) }}>Mesafeli satış sözleşmesi</button> içeriğini okudum ve kabul ediyorum.
							</Input>
							<Btn
								type="submit"
								loading={submitting}
								disabled={submitting}
								className="controls-cta block big primary">
								ÖDEME YAP
							</Btn>
						</div>
					</aside>
				</>
			}
		</Form>
	)
}

Checkout.propTypes = {
	pageProps: PropTypes.object
}

export default Checkout