
import '/assets/styles/partials/gift-code-form.scss';

import { useEffect, useState } from 'react';
import classNames from 'classnames';

// Partials
import { Input, FormFeedback } from '/views/partials/forms';
import Btn from '/views/partials/btn';

// Context
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';

// Services
import bookingServices from '../../services/booking';

const PromoCodeForm = ({ sale, onSuccess }) => {

  const { openModal } = useModals();

  const [submitting, setSubmitting] = useState(false);
  const [code, setCode] = useState('');
  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    if(sale) {
      setCode('');
      setSubmitting(false);
    }
  }, [sale])

  const submit = () => {
    setValidationError(null);

    if(code?.length < 6) {
      setValidationError('Promosyon kodu 6 haneli olmalıdır.');
    }
    else {
      setSubmitting(true);

      bookingServices.submitPromoCode(sale.id, code).then(() => {
        onSuccess?.();
      }).catch((error) => {
        setValidationError(error);
        setSubmitting(false);
      })
    }
  }

  const removeCode = () => {
    openModal('confirm', {
      message: 'Promosyon kodunu kaldırmak istediğinizden emin misiniz?',
      onConfirm: () => {
        setSubmitting(true);
    
        bookingServices.removePromoCode(sale.id, code).then(() => {
          onSuccess?.();
        }).catch((error) => {
          setValidationError(error);
          setSubmitting(false);
        })
      }
    })
  }

  return (
    <div className="section giftcodeform">
        <strong className="giftcodeform-title">Promosyon Kodu</strong>

        {!!validationError &&
          <FormFeedback className="giftcodeform-error" data={validationError} />
        }

        {sale?.promotion_code ?
          <div className="giftcodeform-sum">
            <div className="sum-message">
              Uygulanan Promosyon Kodu <strong>{sale.promotion_code}</strong>
            </div>
            <Btn
              onClick={removeCode}
              disabled={submitting}
              loading={submitting}
              className="giftcodeform-cancelbtn nude primary"
              icon="trash">
              Sil
            </Btn>
          </div>
          :
          <div className="giftcodeform-wrap">
            <Input
              className={classNames('giftcodeform-input low', { error: !!validationError })}
              type="text"
              disabled={submitting}
              value={code}
              onChange={setCode}
              placeholder="Promosyon Kodunuzu Girin" />
            
            <Btn
              onClick={submit}
              disabled={submitting}
              loading={submitting}
              className="giftcodeform-submitbtn primary">
              Uygula
            </Btn>
          </div>

        }
      </div>
  )
}

export default PromoCodeForm;