import '/assets/styles/partials/btn.scss';
import { Btn as RawBtn } from '@wearetla/tla-essentials-tools/partials/btn';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

// Context
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';

const Btn = ({ hollowLink, onClick, href, params, raw, target, ...props }) => {
	const { redirect } = useNavigator();
	
	const hollowProps = useMemo(() => {
		if(hollowLink) {
			return {
				onClick: (e) => {
					e?.stopPropagation?.();
					e?.preventDefault?.();
					redirect(href, params, { raw, target });
					if(onClick) {
						onClick(e);
					}
				}
			}
		}
		else {
			return {
				onClick,
				href,
				params,
				raw,
				target,
			}
		}
	}, [onClick, href, params, hollowLink, raw])

	return <RawBtn {...props} {...hollowProps} />

}

Btn.propTypes = {
	hollowLink: PropTypes.bool,
	onClick: PropTypes.func,
	href: PropTypes.string,
	params: PropTypes.object,
	raw: PropTypes.bool,
	target: PropTypes.string,
}

export default Btn;