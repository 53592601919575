import '/assets/styles/partials/pricebox.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Functions
import formatPrice from '/functions/format-price';

const Pricebox = ({ price: priceProp, className }) => {
	const price = useMemo(() => {
		if(typeof priceProp === 'string') {
			return priceProp
		}
		else if(typeof priceProp === 'number') {
			return formatPrice(priceProp);
		}
		else {
			return false;
		}
	}, [
		priceProp
	]);

	if(price) {
		return (
			<span className={classNames('pricebox', className)}>
				{price}<span className="pricebox-currency">{process.config.mainCurrency.name}</span>
			</span>
		)
	}
	else {
		return null;
	}
}

Pricebox.propTypes = {
	className: PropTypes.string,
	price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Pricebox