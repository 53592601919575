import request from '@wearetla/tla-essentials-tools/utilities/request';
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';

import walletServices from '/services/wallet';

const bookingServices = {
	prepareBooking: (eventId, calendarId, slots) => {
		return new Promise((resolve, reject) => {
			request.post('order/prepare', {
				'event_id' : eventId,
				'calendar_id': calendarId,
				'slots': slots,
			}, (payload, status, request) => {
				if (status === 200 && payload?.order) {
					resolve(payload.order);
				}
				else {
					reject(request?.data?.meta?.messages || 'Siparişiniz oluşturulurken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	getBooking: (bookingId) => {
		return new Promise((resolve, reject) => {
			request.get(`order/detail/${bookingId}`, false, (payload, status, request) => {
				if (status === 200 && payload?.order) {
					resolve(payload.order);
				}
				else {
					reject(request?.data?.meta?.messages || 'Siparişiniz getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	makePayment: walletServices.makePayment,
	makePaymentWithWallet: (saleId) => {
		return new Promise((resolve, reject) => {
			request.post(`order/payment/${saleId}/wallet`, false, (payload, status, request) => {
				if (status === 200) {
					resolve(saleId);
				}
				else {
					reject(request?.data?.meta?.messages || 'Ödemeniz yapılırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	listPhoneBookings: (phone) => {
		return new Promise((resolve, reject) => {
			request.post(`admin/orders/user-active-orders`, { phone: phone }, (payload, status, request) => {
				if (status === 200 && payload?.orders) {
					resolve(payload.orders.map((booking) => ({
						...booking,
						calendar: {
							...booking.calendar,
							formatted_event_date: formatDate(booking.calendar.event_date),
						}
					})));
				}
				else {
					reject(request?.data?.meta?.messages || 'Biletler getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	confirmBookingQr: (qrCode) => {
		return new Promise((resolve, reject) => {
			request.post(`admin/orders/read-qr`, { qr_code: qrCode }, (payload, status, request) => {
				if (status === 200 && payload?.ticket_detail) {
					resolve(payload.ticket_detail);
				}
				else {
					reject(request?.data?.meta?.messages || 'Bilet okunurken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	submitPromoCode: (saleId, giftCode) => {
		return new Promise((resolve, reject) => {
			request.post(`order/promo-code/${saleId}`, { code: giftCode }, (payload, status, request) => {
				if (status === 200) {
					resolve(saleId);
				}
				else {
					reject(request?.data?.meta?.messages || 'Promosyon kodu uygulanırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	removePromoCode: (saleId) => {
		return new Promise((resolve, reject) => {
			request.delete(`order/promo-code/${saleId}`, false, (payload, status, request) => {
				if (status === 200) {
					resolve(saleId);
				}
				else {
					reject(request?.data?.meta?.messages || 'Promosyon kodu iptal edilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	}
}

export default bookingServices;